* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.nav-area {
  display: flex;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  height: 58px;
}

.menus {
  display: flex;
  list-style: none;
}

.menu-items {
  position: relative;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button div {
  margin-top: 1px;
  border-left: 1px;
}
.menu-items a:hover,
.menu-items button:hover {
  background-color: rgba(229, 9, 127, 1);
}

.dropdownnav {
  position: absolute;
  left: 10px;
  right: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08), 0 4px 6px -2px rgba(71, 63, 79, 0.16);
  z-index: 9999;
  min-width: 240px;
  list-style: none;
  display: none;
}

.dropdownnav.show {
  display: block;
}

.dropdownnav.dropdown-submenu {
  position: absolute;
  left: 100%;
  bottom: 0;
}
